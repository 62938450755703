:root {
    --blue-500: #0E2558;
    --blue-400: #122E6E;
    --blue-300: #6C7EA7;
    --blue-200: #C5CEE0;
    --blue-100: #F2F6FD;

    --gold-500: #967921;
    --gold-400: #BC9729;
    --gold-300: #D6BF79;
    --gold-200: #E5DEC8;
    --gold-100: #FCFAF2;

    --gray-300: #2B2C32;
    --gray-200: #787D8F;
    --gray-100: #D4D7E2;
    --gray-50: #F0F1F5;
    --white: #FFFFFF;
}