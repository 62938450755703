@import url('https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './variable.css';

.__ff-neuton {
    font-family: "Neuton", serif;
}

.__ff-lato {
    font-family: "Lato", sans-serif;
}

.__fw-300 {
    font-weight: 300;
}

.__fw-400 {
    font-weight: 400;
}

.__fw-500 {
    font-weight: 500;
}

.__fw-600 {
    font-weight: 600;
}

.__fw-700 {
    font-weight: 700;
}

.__text-heading-1 {
    font-size: 48px;
    line-height: 62px;
}

.__text-heading-2 {
    font-size: 40px;
    line-height: 52px;
}

.__text-heading-3 {
    font-size: 32px;
    line-height: 44px;
}

.__text-title-1 {
    font-size: 24px;
    line-height: 32px;
}

.__text-title-2 {
    font-size: 20px;
    line-height: 26px;
}

.__text-body-1 {
    font-size: 16px;
    line-height: 24px;
}

.__text-body-2 {
    font-size: 14px;
    line-height: 20px;
}

.__text-caption {
    font-size: 12px;
    line-height: 16px;
}

.color-gold-500 {
    color: var(--gold-500);
}

input[type="radio"] {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

input[type="radio"]:checked {
	background-color: #24336B;
	border-color: #24336B;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

input[type="checkbox"] {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

input[type="checkbox"]:checked {
	background-color: #24336B;
	border-color: #24336B;
    background-image: url(../image/checked.png);
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width: 1440px) {
    .container {
        max-width: 100% !important
    }
}

.__custom-range-slider .thumb {
    width: 20px;
    height: 20px;
    cursor: grab;
    border: 2px solid var(--blue-400);
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    margin-top: 2px;
}

.__custom-range-slider .track.track-0,
.__custom-range-slider .track.track-2 {
    height: 4px;
    background-color: var(--gray-100);
}
.__custom-range-slider .track.track-1 {
    height: 4px;
    background-color: var(--blue-400);
}

.marker-icon {
    background-position: center;
    background-size: 22px 22px;
    border-radius: 50%;
    height: 22px;
    left: 4px;
    position: absolute;
    text-align: center;
    top: 3px;
    transform: rotate(45deg);
    width: 22px;
}
.marker {
    height: 30px;
    width: 30px;
}
.marker-content {
    background: #c30b82;
    border-radius: 50% 50% 50% 0;
    height: 30px;
    left: 50%;
    margin: -15px 0 0 -15px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    width: 30px;
}
.marker-content::before {
    background: #ffffff;
    border-radius: 50%;
    content: "";
    height: 24px;
    margin: 3px 0 0 3px;
    position: absolute;
    width: 24px;
}

.marker-content-text {
    position: relative;
    width: max-content;
    display: inline-block;
    background-color: #fff;
    color: #24336B;
    /* border: 1px solid #24336B; */
    right: 100%;
    bottom: 30%;
    border-radius: 16px;
    padding: 8px;
    font-size: 14px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.marker-content-text:before {
    content: '';
    position: absolute;
    top: 35px;
    left: calc(50% - 6px);
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: #FFFFFF transparent;
}

/* tomtom */
.mapboxgl-ctrl-bottom-right {
    z-index: 0;
}

.__blueprint-map {
    cursor: pointer;
}

.__blueprint-map.active div {
    background-color: #D6AD60;
}

.__blueprint-map.active p {
    color: #D6AD60;
}

.__blueprint-map:hover div {
    background-color: #D6AD60;
}

.__blueprint-map:hover p {
    color: #D6AD60;
}