.react-datepicker__header {
    border-bottom: none !important;
    background-color: white !important;
}

.react-datepicker__day-names {
    padding-top: 10px;
}

.react-datepicker__month-container {
    padding: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.react-datepicker__day-name {
    color: #787d8f !important;
}

.react-datepicker__month {
    margin: 16px !important;
}

.DateRangePickerInput__withBorder {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
}

.DateInput {
    width: 130px;
    background-color: #ffffff;
}

.DateRangePickerInput_arrow {
    padding-right: 5px;
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.DayPickerNavigation__horizontal .DayPickerNavigation_button:first-child {
    left: 22px;
}

.DayPickerNavigation__horizontal .DayPickerNavigation_button:last-child {
    right: 22px;
}

.DayPickerNavigation__horizontal .DayPickerNavigation_button {
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #24336b;
    border: 1px double #24336b;
    color: #fff;
}

.CalendarDay__selected div {
    color: #fff;
}

.CalendarDay__selected div div span {
    color: #fff;
}

.CalendarDay {
    border: none;
}

.CalendarDay__selected_span {
    background: #f0f2fa;
    border: 1px double #f0f2fa;
    color: #2b2c32;
}

.CalendarDay__selected_span:hover {
    background: #f0f2fa;
    border: 1px double #f0f2fa;
    color: #2b2c32;
}

.CalendarDay
    .CalendarDay_1
    .CalendarDay__default
    .CalendarDay__default_2:hover {
    color: #2b2c32;
    background: #f0f2fa;
    border: 1px double #f0f2fa;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #f0f2fa;
    color: #fff;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #f0f2fa;
    border: 1px double #f0f2fa;
    color: #2b2c32;
}

.CalendarDay__hovered_span:active {
    background: #24336b;
    border: 1px double #24336b;
    color: #fff;
}

.DateInput.DateInput_1 input {
    color: #2b2c32;
    font-size: 16px;
    font-weight: 500;
    font-family: "Lato", sans-serif;
    border: none;
}

@media screen and (max-width: 1024px) {
    .DateInput.DateInput_1 input {
        font-size: 14px;
        font-weight: 500;
    }
}

/* DateRangePicker.css */
.date-range-picker-container {
    width: 100% auto; /* Lebar penuh */
    max-width: 400px; /* Lebar maksimum untuk mobile */
    /* margin: 0 auto; Tengah secara horizontal */
}

/* Media query untuk layar berukuran kecil (mobile) */
@media (max-width: 768px) {
    .date-range-picker-container {
        max-width: 100%; /* Lebar penuh pada layar kecil */
        padding: 0 10px; /* Padding di sisi untuk ruang pernapasan */
    }

    .DateInput {
        width: 100px;
        background-color: #ffffff;
    }
}

/* Custom React Date Range */
.rdrDayActive span {
    border-radius: 0 !important;
}
.rdrStartEdge,
.rdrEndEdge {
    color: #24336b !important;
}
.rdrInRange + .rdrDayNumber span {
    color: #2b2c32 !important;
}
