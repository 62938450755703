@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* transition: 0.3s ease; */
    font-family: 'Lato', sans-serif;
    color: #2B2C32;
}